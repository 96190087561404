import React, { Component } from 'react'
import Header from './components/header/header';
import 'bootstrap/dist/css/bootstrap.css';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Homepage from './components/homepage/homepage';
import NotFound from './components/notfound/notfound'
import Work from './components/work/work'
import Footer from './components/footer/footer'
import Post from './components/post/post'
import Categories from './components/categories/categories'
import Latest from './components/latest/latest'
import './App.css';
import './responsive.css'
import Navigation from './components/mobile/navigation'
import Home from './components/mobile/home'
import Mheader from './components/mobile/header'
import Mpost from './components/mobile/post'
import Entertainment from './components/categories/entertainment'
import Travel from './components/categories/travel'
import Food from './components/categories/food'
import Fashion from './components/categories/fashion'
import Movies from './components/categories/movies'
import Beauty from './components/categories/beauty'
import Talent from './components/categories/talent'
import Videos from './components/videos/videos'
import Mfooter from './components/mobile/footer'
import writers from './components/writers/writers'
import win from './components/win/win'
import spooky from './components/categories/spooky'
import {isMobileOnly} from 'react-device-detect';
import about from './components/about/about'
import contact from './components/contact/contact'
import Privacy from './components/privacy/privacy'



class App extends Component {
   
    
  
    render() {
     

  if (isMobileOnly) {
        return (
           
            <BrowserRouter>
                <div className="mobile">
                
             <Mheader></Mheader> 
    
                    <Switch>
    
                        <Route path="/" component={Home} exact/>
                        <Route path="/post/:slug" component={Mpost} exact/>
                        <Route path="/category/entertainment" component={Entertainment} />
                        <Route path="/category/spooky" component={spooky} />
                        <Route path="/category/travel" component={Travel} />
                        <Route path="/category/food" component={Food} />
                        <Route path="/category/fashion" component={Fashion} />
                        <Route path="/category/movies" component={Movies} />
                        <Route path="/category/talent" component={Talent} />
                        <Route path="/category/beauty" component={Beauty} />
                        <Route path="/our-work" component={Work} exact/>
                        <Route path="/latest" component={Latest} exact/>
                        <Route path="/videos" component={Videos} exact/>
                        <Route path="/privacy-policy" component={Privacy} exact/>
                        <Route path="/writers" component={writers} exact/>
                        <Route path="/win" component={win} exact/>
                        <Route path="/about" component={about} exact/>
                        <Route path="/contact" component={contact} exact/>
                        <Route component={NotFound} />
                      
                    </Switch>
    
                    <Navigation></Navigation>
                    <Mfooter> </Mfooter>
   
                </div>
            </BrowserRouter>
           
        )}
        else{
            return (
               
                <BrowserRouter>
                    <div className='desktop'>
        
                        <Header></Header>
        
                        <Switch>
        
                            <Route path="/" component={Homepage} exact/>
                            <Route path="/post/:slug" component={Post} exact/>
                            <Route path="/category/entertainment" component={Entertainment} />
                            <Route path="/category/travel" component={Travel} />
                            <Route path="/category/fashion" component={Fashion} />
                            <Route path="/category/food" component={Food} />
                            <Route path="/category/beauty" component={Beauty} />
                            <Route path="/category/talent" component={Talent} />
                            <Route path="/category/movies" component={Movies} />
                            <Route path="/category/spooky" component={spooky} />
                            <Route path="/privacy-policy" component={Privacy} exact/>
                            <Route path="/latest" component={Latest} exact/>
                            <Route path="/videos" component={Videos} exact/>
                            <Route path="/writers" component={writers} exact/>
                            <Route path="/about" component={about} exact/>
                            <Route path="/win" component={win} exact/>
                            <Route path="/contact" component={contact} exact/>
                            <Route component={NotFound} />
                          
                        </Switch>
        
                        <Footer></Footer>
        
                    </div>
                </BrowserRouter>
               
            )
        }
    }
}




export default App;
