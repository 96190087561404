import React, { Component } from 'react'
import './about.css'
import { Row, Button} from 'react-bootstrap';
import axios from '../../axios'
import parse, {domToReact} from 'html-react-parser';
import Loader from 'react-loader-spinner'
import abt from './../../assets/videos/about.mp4'
 class about extends Component {
   
    render() {
    
        return (
            <div>
               <div className="headerbg">
                   <h2>{this.props.match.params.slug}</h2>
                   <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
    <source src={abt} type="video/mp4" />
  </video>            
                        
                    </div>  
               <div className="container">
                   <Row>
                  <div className="aboutcont">
                      <h2 style={{textAlign : 'center'}}>About Us</h2>
                      <br/>
                      
                  The team of “WE SPOT YOU” work together to bring to you the latest talent (and of course, the latest gossip too!) and the latest scoop about what’s going on in the celeb world. Tune in to our channel on a regular basis to spend the most entertaining minutes and hours of your day as we provide you with the best gossip in town (and on social media) along with fun activities and interviews. <br/>
Forward your questions and ideas to our people and get a chance to have your questions and ideas featured in our interviews, videos and posts along with your name. <br/>
 At “WE SPOT YOU”, our only aim is:	<br/>
Entertainment, Entertainment AND… (Yes, you guessed it right!) Entertainment! <br/>
So, remember to come online to watch our videos and interviews where we plan to provide you with amazing activities and inside info from celebs that will leave you feeling refreshed, amazed and of course, entertained. 
And we plan to do all of this with our special Desi Tadka that is hatke (different) from all the other content out there. <br/>
Do not forget to hit the like button and subscribe to our updates so that you never miss any of our special uploads. 

                  </div>
                   </Row>
               </div>
            </div>
        )
        }
}

export default about