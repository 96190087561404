import React, { Component } from 'react'
import './notfound.css'
import {NavLink} from 'react-router-dom';
import {Helmet} from 'react-helmet'

class NotFound extends Component {
  
  render() {
    var path = window.location.pathname;
path = path[0] === '/' ? path.substr(1) : path;

    return (
      <div className="page-container">
       <Helmet>
                <meta charSet="utf-8" />
                <title>Page not found | Controlsoft</title>
        </Helmet>
       <div className="container">
      <div className="svgel">
      <svg version="1.1" id="Layer_1"  x="0px" y="0px"
	 viewBox="0 0 314 135">

<text transform="matrix(1 0 0 1 10.0175 122.7719)"><tspan x="0" y="0" className="st0 st1 st2">4 </tspan><tspan x="132.2" y="0" className="st0 st1 st2 st3">  </tspan><tspan x="202.4" y="0" className="st0 st1 st6">4</tspan></text>
<path className="st4" d="M153.1,22.6c-27.3,0-49.5,22.2-49.5,49.5s22.2,49.5,49.5,49.5c27.3,0,49.5-22.2,49.5-49.5
	S180.5,22.6,153.1,22.6z M180.8,72.1c0,3.4-0.6,6.6-1.7,9.6l-38.5-34.2c3.8-1.9,8.1-3,12.6-3C168.4,44.4,180.8,56.8,180.8,72.1z
	 M125.5,72.1c0-3.3,0.6-6.4,1.6-9.3l38.3,34c-3.7,1.8-7.9,2.9-12.3,2.9C137.9,99.7,125.5,87.3,125.5,72.1z"/>
<path className="st5" d="M189.5,38.5c6.2,8.2,9.8,18.5,9.8,29.6c0,27.3-22.2,49.5-49.5,49.5c-14.4,0-27.3-6.1-36.3-15.9
	c9,12.1,23.4,19.9,39.7,19.9c27.3,0,49.5-22.2,49.5-49.5C202.6,59.1,197.6,47.3,189.5,38.5z"/>
</svg>
<h4 className="pathh">Oops! <span className="path">{path}</span>  page not found</h4>
<NavLink className="goback" to="/">Go Back</NavLink>
      </div>
       </div>
        
      </div>
    )
  }
}


export default NotFound;