import React, { Component } from 'react'
import './win.css'
import { Row, Button} from 'react-bootstrap';
import axios from '../../axios'
import parse, {domToReact} from 'html-react-parser';
import Loader from 'react-loader-spinner'
import wi from './../../assets/videos/win.mp4'
 class win extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            win:"",
            isLoading: true,
          
        };
    }

    winPostLoad() {
        axios.get( `wp-json/wp/v2/pages/54`)
                          .then( res => {
       
                           const data = res.data;
                            const win = <React.Fragment>
                                <div>{parse(data.content.rendered)}</div>
                            </React.Fragment>


                              this.setState({isLoading:false, win })
                             
                          } );
           }

           componentDidMount() {
            this.winPostLoad();
           
        }
    render() {
        if (this.state.isLoading) {
            return (
                <div className="loader">
                    <Loader type="Rings" color="#47ccde" height="100" width="100"/>
                </div>
            )
        } else {
        return (
            <div>
                <div className="headerbg">
                   <h2>{this.props.match.params.slug}</h2>
                   <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
    <source src={wi} type="video/mp4" />
  </video>            
                        
                    </div>
               <div className="container">
                   <Row>
                  <div className="wincont">
                  {this.state.win}
                  </div>
                   </Row>
               </div>
            </div>
        )
        }
    }
}

export default win