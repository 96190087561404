import React, { Component } from 'react'
import './mobile.css'
import {Row, Button} from 'react-bootstrap';
import axios from '../../axios'
import Loader from 'react-loader-spinner'
import {Helmet} from 'react-helmet'
import parse, {domToReact} from 'html-react-parser';
import Moment from 'moment'
import {NavLink} from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import AnchorLink from 'react-anchor-link-smooth-scroll'

 class home extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            latest: [],
            isLoading: true,
            offset: 0,
            perPage:11,
            currentPage: 0,
            query: '',
            results: [],
            ln: []
        };
    }
    truncate(str, no_words) {
        var tro = str
            .split(" ")
            .splice(0, no_words)
            .join(" ");
        var regex = /(<([^>]+)>)/ig

        return tro.replace(regex, "");
    }
    handlePageClick = (data) => {
        const selectedPage = data.selected;
        const offset = selectedPage * this.state.perPage;
        
        this.setState({ currentPage: selectedPage, offset: offset, isLoading: false }, () => {
          this.latestNews()
        });
        
      };

    latestNews() {
        axios
            .get(`wp-json/home/allpost`, {})
            .then(res => {

                const data = res.data
                const count = data.length
                const split = data.slice(this.state.offset, this.state.offset + this.state.perPage)

                const allPosts = <React.Fragment>
                   
                   
                        {
                            split.map((d,i) => {
                              
                                    return <div className={i % 2 !== 0 ? 'ln2' : 'ln'}>
                                            <div className="lnimage">
                                            <NavLink to={`/post/${d.post_slug}`}>
                                            <img src={d.post_image} alt=""/></NavLink>
                                                
                                                <NavLink className="category" to={`/category/${d.slug}`}>{d.category_name}</NavLink>
                                            </div>
    
                                            <div className="ln-right">
                                            <p className="date">{Moment(new Date(d.date).toString()).format("LL")}</p>
                                                <h2 className="title">
                                                    <NavLink to={`/post/${d.post_slug}`}>
                                                        {d.post_title}</NavLink>
                                                </h2>
                                                
                                                
                                                
                                                <div className="ed">
                                                    <NavLink className='readmore' to={`/post/${d.post_slug}`}>
                                                        Read Article</NavLink>
    
                                                </div>
                                            </div>
    
                                        </div>
                                   
                                
                            })
                        }
                   
                   
                </React.Fragment>
                

                

                this.setState({allPosts, isLoading: false, pageCount: Math.ceil(data.length / this.state.perPage),count});

            })
            .catch((error) => {
                console.log(error)
            })

    }
    componentDidMount() {
        this.latestNews();
    }
    render() {

        let paginate;
        if( this.state.count < 11){
          paginate = <div></div>
        }
        else {
           paginate = 
           <ReactPaginate previousLabel={"prev"}
           nextLabel={"next"}
           breakLabel={"..."}
           breakClassName={"break-me"}
           pageCount={this.state.pageCount}
           marginPagesDisplayed={2}
           pageRangeDisplayed={1}
           onPageChange={this.handlePageClick}
           containerClassName={"pagination"}
           subContainerClassName={"pages pagination"}
           activeClassName={"active"} 
           
           /> 
    
    
        }

        if (this.state.isLoading) {
            return (
                <div className="loader">
                    <Loader type="Rings" color="#47ccde" height="100" width="100"/>
                </div>
            )
        } else {
        return (
            <div className='mobile'>
                <div className="container" id="top">
               
                {this.state.allPosts}

                <div>
    
  
 
    
  </div>
                </div>
               <AnchorLink href='#top'>{paginate}</AnchorLink>
               
            </div>
        )
        }
    }
}
export default home;
