import React, { Component } from 'react'
import './writers.css'
import YouTube from 'react-youtube';
import axios from 'axios';
import Moment from 'moment'
import Loader from 'react-loader-spinner'
import parse, {domToReact} from 'html-react-parser';
import writer from './../../assets/videos/writers.mp4'
const opts = {
    height: 'auto',
    width: '100%',
    playerVars: { 
      autoplay: 0
    }
  };

 class writers extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: true,
        }
      
    }

    receivedata() {
        axios
            .get(`wp-json/wp/v2/users`, {})
            .then(res => {
              
                const data = res.data;

               const user = data.map(u => 
                <div>
                    
                    {u.acf.show_in_page == true ? parse(`<div class="col-md-4"><div class='col-md-12 writerbox '><div className="writerimg"><img src=${u.mpp_avatar.full} alt=""/></div><div class='wcontent'><h3>${u.name}</h3><p>${u.description}</p> <div class='wsocial'><a href=${u.acf.facebook}><i class="fab fa-facebook-f"></i></a><a href=${u.acf.instagram}><i class="fab fa-instagram"></i></a><a href=${u.acf.twitter}><i class="fab fa-twitter"></i></a></div></div></div></div>`) : ''}
               
                </div>
                )

               

                this.setState({isLoading: false,user});
            })
    }

    componentDidMount() {
        this.receivedata()
        
        
    }

    render() {

        if (this.state.isLoading) {
            return (
                <div className="loader">
                    <Loader type="Rings" color="#47ccde" height="100" width="100"/>
                </div>
            )
        } else {
        
        return (
            <div className='writer'>
<div className="headerbg">
                   <h2>{this.props.match.params.slug}</h2>
                   <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
    <source src={writer} type="video/mp4" />
  </video>            
                        
                    </div>
                <div className="container">
                    <div className="row">
                    {this.state.user}
                    </div>
                </div>
            </div>
        )
        }
    }
}

export default writers