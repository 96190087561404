import React, {Component} from 'react';
import { Row, Button} from 'react-bootstrap';
import axios from '../../axios'
import './post.css';
import parse, {domToReact} from 'html-react-parser';
import Loader from 'react-loader-spinner'
import $ from 'jquery'
import {NavLink} from 'react-router-dom';
import {Helmet} from 'react-helmet'
import Moment from 'moment'
import {FacebookShareButton,FacebookIcon,TwitterShareButton,PinterestShareButton,PinterestIcon, EmailShareButton , EmailIcon ,TwitterIcon,WhatsappShareButton,WhatsappIcon} from 'react-share';

class Post extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: true,
        };
    }



    postLoad() {
        if ( this.props.match.params.slug ) {
            if ( !this.state.loadedPost || (this.state.loadedPost && this.state.loadedPost.slug !== +this.props.match.params.slug) ) {
               axios.get( 'wp-json/wp/v2/posts?slug=' + this.props.match.params.slug)
                   .then( res => {
                    
                    const data = res.data;

                    const seo = res.data



                    const mtag = seo.map(s => 
                        <Helmet>
                <meta charset="UTF-8" />
                <meta name="description" content={s.excerpt.rendered} />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={parse(s.title.rendered)} />
                <meta property="og:url" content='http://wespotyou.com' />
                <meta property="og:description" content={s.excerpt.rendered} />
                <meta property="og:site_name" content='WeSpotYou' />
                <meta property="og:image" content={s.better_featured_image.source_url} />
                <meta property="og:image:secure_url" content={s.better_featured_image.source_url} />
                <meta name="twitter:title" content={parse(s.title.rendered)} />
                <meta name="twitter:image" content={s.better_featured_image.source_url} />
           </Helmet>

                        
                        )

                    

                    const post = data.map(p => 
                        <React.Fragment>
                            
                        <h1>{parse(p.title.rendered)}</h1>
                        <h4><i>{p.acf.sub_heading}</i></h4>
                        <div style={{marginBottom : '10px'}}><span className='authimg'><img src={p.writer} alt=""/></span><span className="auth">{p.author}</span>
                        <span style={{margin: '0 5px 0 10px'}}><i class="far fa-clock"></i></span><span>{Moment(new Date(p.date).toString()).format("LL")}</span>
                        </div>
                        <div className="post-img">
                            <img src={p.better_featured_image.source_url} alt=""/>
                            
                        </div>
                        <div className="time-auth">
                           
                        </div>
                        <div className="content">
                            {parse(p.content.rendered)}
                        </div>
                        </React.Fragment>
                        )
                    
    
                       this.setState({isLoading:false, post, mtag})
                      
                   } );
                   
           }
       }

    }

    sideBar(){
        axios
        .get(`wp-json/acf/v3/sidebar`, {})
        .then(res => {

            const sbdata = res.data[0].acf.banner
            const banner2 = res.data[0].acf.second_banner

           


            this.setState({sbdata, banner2})

        })
        .catch((error) => {
            console.log(error)
        })
    }

   

    componentDidMount() {
        this.postLoad();
         this.sideBar()
       
    }
   

    render() {

        if (this.state.isLoading) {
            return (
                <div className="loader">
                    <Loader type="Rings" color="#47ccde" height="100" width="100"/>
                </div>
            )
        } else {

            return (
                <div className="post">
                    {this.state.mtag}
                    <div className="container">
                        <div className="row">
                            <div className="blogleft">
                                {this.state.post}
                                <h5 style={{textAlign: "center"}}>Share this post</h5>
                                <div className="desksocial">
                                    
                        <FacebookShareButton url = {window.location.href} > <FacebookIcon size={50}></FacebookIcon> </FacebookShareButton>
                        <WhatsappShareButton url = {window.location.href} > <WhatsappIcon size={50}></WhatsappIcon> </WhatsappShareButton>
                        <TwitterShareButton url = {window.location.href}  > <TwitterIcon size={50}></TwitterIcon> </TwitterShareButton>
                        <EmailShareButton url = {window.location.href} subject='Read This Awesome Post by We Spot You' openWindow={true}> <EmailIcon size={50}></EmailIcon> </EmailShareButton>
                        {/* <PinterestShareButton media = {window.location.href} > <PinterestIcon size={50}></PinterestIcon> </PinterestShareButton> */}
                        </div>
                            </div>
                            <div className="blogright">
                               <div className="col-md-12 sbbanner">
                                   <img src={this.state.sbdata} alt=""/>
                                  
                                  
                               </div>
                               <div className="col-md-12 sbbanner">
                               <img src={this.state.banner2} alt=""/>
                                  
                                  
                               </div>
                            </div>
                        </div>
                      
                    </div>
                </div>
            )
        };
    }
}

export default Post;
