import React, {Component} from 'react';
import { Row, Button} from 'react-bootstrap';
import axios from '../../axios'
import './categories.css';
import Moment from 'moment'
import parse, {domToReact} from 'html-react-parser';
import Loader from 'react-loader-spinner'
import $ from 'jquery'
import {NavLink} from 'react-router-dom';
import {Helmet} from 'react-helmet'
import ReactPaginate from 'react-paginate';
class Categories extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            cat:[],
            isLoading: true,
            offset: 0,
            perPage:11,
            currentPage: 0,
            query: '',
            results: [],
        };
    }
    truncate(str, no_words) {
        var tro = str
            .split(" ")
            .splice(0, no_words)
            .join(" ");
        var regex = /(<([^>]+)>)/ig

        return tro.replace(regex, "");
    }
    postPerPage(){
        axios
            .get(`wp-json/setting/options`, {})
            .then(res => {
                const data = res.data
                const perPage = data.posts_per_page
                this.setState({perPage})
            })
            .catch((error) => {
                console.log(error)
            })
    }

    handleInputChange = () => {
        this.setState({
          query: this.search.value
        }, () => {
          if (this.state.query && this.state.query.length > 1) {
            if (this.state.query.length % 2 === 0) {
              this.catPostLoad()
            }
            
          } 
          else if (this.state.query.length === 0) {
            this.catPostLoad()
          }
        })
      }


    catPostLoad() {

        
        if ( this.props.match.params.slug ) {
           
               axios.post( `/wp-json/home/category/${this.props.match.params.slug}`)
                   .then( res => {

                    
                    
                    const data = res.data;
                    const split = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                    var child_el = '';

                    

                    const cat = <React.Fragment>

                    <Row>

                        {split.map((d, i) => {
                            if (i === 0) {
                                return <div className="col-md-12">
                                    <div className="ln-box1">
                                        <div className="post-image1">
                                            <img src={d.post_image} alt=""/>
                                            <NavLink className="category" to={`/category/${d.slug}`}>{d.category_name}</NavLink>
                                        </div>

                                        <div className="ln-content">

                                            <h2 className="title">
                                                <NavLink to={`/post/${d.post_slug}`}>
                                                    {d.post_title}</NavLink>
                                            </h2>
                                            <span className='author'>By {d.display_name}</span>
                                            <span className="date">{Moment(new Date(d.date).toString()).format("LL")}</span>
                                            <p className='excerpt'>{this.truncate(`${d.post_content}`, 50)}</p>
                                            <div className="entry-details">
                                                <NavLink className='readmore' to={`/post/${d.post_slug}`}>
                                                    Read More</NavLink>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            } else {
                                child_el += `<div class="col-md-6"> <div class="ln-box2">
                    <div class="post-image">
                    <img src=${d
                                    .post_image} alt=""/>
                    <NavLink class="category" id='remove' to='/category/${d
                                    .category_name}'>${d
                                    .category_name}</NavLink>
                    </div>
                     <div class="ln-content">
                     
                     <h2 class='title'><NavLink id='remove' to='/post/${d
                                    .post_slug}'>${d
                                    .post_title}</NavLink></h2>
                     <span class='author'>By ${d
                                    .display_name}</span>
                     <span class="date" id='date'>${Moment(new Date(d.date).toString())
                                    .format("LL")}</span>
                     <p class='excerpt'>${this
                                    .truncate(`${d.post_content}`, 20)}</p>
                                        <div className="entry-details">
                                        <NavLink id='remove' className='readmore' to='/post/${d
                                    .post_slug}'>
                                            Read More</NavLink>
                                        </div>
                     </div>
                     </div></div>`
                            }
                        })}

                        <div className="col-md-12">

                            <div>{parse(child_el, {
                                    replace: ({attribs, children}) => attribs && attribs.id === 'remove' && <NavLink to={attribs.to}>{domToReact(children)}</NavLink>
                                })}</div>

                        </div>

                    </Row>

                </React.Fragment>
                    
    
                       this.setState({isLoading:false, cat,pageCount: Math.ceil(data.length / this.state.perPage),})
                      
                   } );
                   
           
       }

       

    }

   

     componentDidUpdate() {
     this.catPostLoad();

       
    }
     componentDidMount() {
        this.catPostLoad();
        this.postPerPage()
       
    }

    render() {

        if (this.state.isLoading) {
            return (
                <div className="loader">
                    <Loader type="Rings" color="#000" height="100" width="100"/>
                </div>
            )
        } else {

            return (
                <div className="categories">
                   <div className="headerbg">
                        <h2>{this.props.match.params.slug}</h2>
                    </div>
                   
                    <div className="container">
                        <div className="row">
                            <div className="blogleft">
                                {this.state.cat}
                            </div>
                            <div className="blogright">
                            <div className="searchbar">
                            <form className="searchform">
       <input
         placeholder="Search for..."
         ref={input => this.search = input}
         onChange={this.handleInputChange}
       />
       <p>{this.state.query}</p>
     </form>
     <i class="fas fa-search sricon"></i>
                            </div>
                            </div>
                        </div>
                        <ReactPaginate previousLabel={"previous"}
                       nextLabel={"next"}
                       breakLabel={"..."}
                       breakClassName={"break-me"}
                       pageCount={this.state.pageCount}
                       marginPagesDisplayed={2}
                       pageRangeDisplayed={5}
                       onPageChange={this.handlePageClick}
                       containerClassName={"pagination"}
                       subContainerClassName={"pages pagination"}
                       activeClassName={"active"} 
                       
                       /> 
                    </div>
                </div>
            )
        };
    }
}

export default Categories;
