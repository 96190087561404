import React, { Component } from 'react';
import axios from '../../axios'
import {NavLink} from 'react-router-dom';
import ShareButton from 'react-web-share-button';



class Mfooter extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
        
      
    };
}
recievedata() {
  axios
      .get(``, {})
      .then(res => {
          const footer = res.data.acf

         
          



          this.setState({
             
            
          });

      })
      .catch((error) => {
          console.log(error)
      })

}



componentDidMount() {
//   var shareButton = document.querySelector('.sharebtn');
//   shareButton.addEventListener('click', event => {
//    if (navigator.share) {
//      navigator.share({
//        title: 'WeSpotYou',
//        url: document.location.href
//      }).then(() => {
//        console.log('Thanks for sharing!');
//      })
//      .catch(console.error);
//    } else {
//      // fallback
//    }
//  });

}
  render() {
    return (
        <div className="mfooter">
          <div className="dockicon">
           
          <NavLink to="/"><i class="fas fa-home"></i></NavLink>
            <a href="https://wa.me/+919674716730"><i class="fab fa-whatsapp"></i></a>

            <NavLink to='/videos'><i class="fas fa-video"></i></NavLink>
           
            
            {/* <i className="fas fa-share-alt sharebtn" onClick={ () => (navigator.share ? navigator.share({ title: 'WeSpotYou', url: document.location.href }) : console.log('error'))}></i> */}
            <NavLink to='/contact'><i class="fas fa-address-book"></i></NavLink>
           
          </div>
        </div>
    );
  }
}

export default Mfooter;
