import React, {Component} from 'react';
import {Row, Button} from 'react-bootstrap';
import axios from '../../axios'
import './homepage.css';
import Loader from 'react-loader-spinner'
import $ from 'jquery'
import {NavLink} from 'react-router-dom';
import {Helmet} from 'react-helmet'
import parse, {domToReact} from 'html-react-parser';
import Moment from 'moment'
import {FacebookShareButton, FacebookIcon,FacebookShareCount} from 'react-share';

class Homepage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            slug: [],
            isLoading: true
        };
    }
    headerPosts(){
        axios
        .get(`wp-json/acf/v3/pages/37`, {})
        .then(res => {

            const hp = res.data.acf.header_posts
            console.log(hp);
            var grid_r = '';
           var  grid_r2 = '';
           var  grid_r3 = '';
           var  grid_r4 = '';

            const hposts =
            <React.Fragment>
            {hp.map((p, i) => {
                    if (i === 0) {
                        return  <div className="grid-left">
                            <NavLink to={`/post/${p.slug}`}>
                            <h2>{p.post_title}</h2>
                        <div className="bgc1" style={{backgroundImage: `url(${p.featured_image})`}}></div>
                        </NavLink>
                        <NavLink  className="cate" to={`/category/${p.category_name[0].slug}`}>{p.category_name[0].name}</NavLink>
                    </div>
                    }
                    else if(i === 1  ){
                        grid_r += `<NavLink id='remove' to='/post/${p.slug}'><h2>${p.post_title}</h2><div className="bgc${i+1}" style="background-image: url(${p.featured_image})"></div></NavLink><NavLink id='remove' to='/category/${p.category_name[0].slug}'><p className='cate'>${p.category_name[0].name}</p></NavLink>`
                    }
                    else if(i===2){
                        grid_r2 += `<NavLink id='remove' to='/post/${p.slug}'><h2>${p.post_title}</h2><div className="bgc${i+1}" style="background-image: url(${p.featured_image})"></div></NavLink><NavLink id='remove' to='/category/${p.category_name[0].slug}'><p className='cate'>${p.category_name[0].name}</p></NavLink>`
                    }
                    else if(i===3){
                        grid_r3 += `<NavLink id='remove' to='/post/${p.slug}'><h2>${p.post_title}</h2><div className="bgc${i+1}" style="background-image: url(${p.featured_image})"></div></NavLink><NavLink id='remove' to='/category/${p.category_name[0].slug}'><p className='cate'>${p.category_name[0].name}</p></NavLink>`
                    }
                    else if(i===4){
                        grid_r4 += `<NavLink id='remove' to='/post/${p.slug}'><h2>${p.post_title}</h2><div className="bgc${i+1}" style="background-image: url(${p.featured_image})"></div></NavLink><NavLink id='remove' to='/category/${p.category_name[0].slug}'><p className='cate'>${p.category_name[0].name}</p></NavLink>`
                    }
            })}
            <div className="grid-right">

<div className="grid-up">
<div>{parse(grid_r, {
                                    replace: ({attribs, children}) => attribs && attribs.id === 'remove' && <NavLink to={attribs.to}>{domToReact(children)}</NavLink>
                                })}</div>
<div>{parse(grid_r2, {
                                    replace: ({attribs, children}) => attribs && attribs.id === 'remove' && <NavLink to={attribs.to}>{domToReact(children)}</NavLink>
                                })}</div>
</div>
<div className="grid-down">
<div>{parse(grid_r3, {
                                    replace: ({attribs, children}) => attribs && attribs.id === 'remove' && <NavLink to={attribs.to}>{domToReact(children)}</NavLink>
                                })}</div>
<div>{parse(grid_r4, {
                                    replace: ({attribs, children}) => attribs && attribs.id === 'remove' && <NavLink to={attribs.to}>{domToReact(children)}</NavLink>
                                })}</div> 
</div>

</div>
                </React.Fragment>

                

            this.setState({hposts})

        })
        .catch((error) => {
            console.log(error)
        })
    }
    getCategoryBg() {
        axios
            .get(`wp-json/acf/v3/categories`, {})
            .then(res => {

                const bgdata = res.data

                this.setState({})

            })
            .catch((error) => {
                console.log(error)
            })

    }
    settingsData() {
        axios
            .get(``, {})
            .then(res => {

                this.setState({})

            })
            .catch((error) => {
                console.log(error)
            })

    }

    truncate(str, no_words) {
        var tro = str
            .split(" ")
            .splice(0, no_words)
            .join(" ");
        var regex = /(<([^>]+)>)/ig

        return tro.replace(regex, "");
    }

    latestNews() {
        axios
            .get(`wp-json/home/post`, {})
            .then(res => {

                const data = res.data
                var child_el = '';

                const ln = <React.Fragment>

                    <Row>

                        {data.map((d, i) => {
                            if (i === 0) {

                                return <div className="col-md-6">
                                    <div className="ln-box1">
                                        <div className="post-image1">
                                        <NavLink to={`/post/${d.post_slug}`}><img src={d.post_image} alt=""/></NavLink>
                                            <NavLink className="category" to={`/category/${d.category_name}`}>{d.category_name}</NavLink>
                                        </div>

                                        <div className="ln-content">

                                            <h2 className="title">
                                                <NavLink to={`/post/${d.post_slug}`}>
                                                    {d.post_title}</NavLink>
                                            </h2>
                                            <span className='author'>By {d.display_name}</span>
                                            <span className="date">{Moment(new Date(d.date).toString()).format("LL")}</span>
                                            <p className='excerpt'>{this.truncate(`${d.post_content}`, 50)}</p>
                                            <div className="entry-details">
                                            <NavLink className='readmore' to={`/post/${d.post_slug}`}>
                                                Read More</NavLink>
                                                
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            } else {

                                child_el += `<div class="col-md-6"> <div class="ln-box2">
                <div class="post-image">
                <NavLink id='remove' to='/post/${d
                    .post_slug}'><img src=${d
                                    .post_image} alt=""/></NavLink>
                <NavLink class="category" id='remove' to='/category/${d
                                    .category_name}'>${d
                                    .category_name}</NavLink>
                </div>
                 <div class="ln-content">
                 
                 <h2 class='title'><NavLink id='remove' to='/post/${d
                                    .post_slug}'>${d
                                    .post_title}</NavLink></h2>
                 <span class='author'>By ${d
                                    .display_name}</span>
                 <span class="date" id='date'>${Moment(new Date(d.date).toString())
                                    .format("LL")}</span>
                 <p class='excerpt'>${this
                                    .truncate(`${d.post_content}`, 20)}</p>
                                    <div className="entry-details">
                                    <NavLink id='remove' className='readmore' to='/post/${d
                                        .post_slug}'>
                                        Read More</NavLink>
                                    </div>
                 </div>
                 </div></div>`

                            }

                        })}

                        <div className="col-md-6">

                            <div>{parse(child_el, {
                                    replace: ({attribs, children}) => attribs && attribs.id === 'remove' && <NavLink to={attribs.to}>{domToReact(children)}</NavLink>
                                })}</div>

                        </div>

                    </Row>

                </React.Fragment>

                this.setState({ln, isLoading: false});

            })
            .catch((error) => {
                console.log(error)
            })

    }

    worldNews() {
        axios
            .get(`wp-json/home/entertainment`, {})
            .then(res => {

                const data = res.data

                const wn = <React.Fragment>

                    <Row>

                        {data.map((d) => {
                            return <div className="col-md-3">
                                <div className="ln-box1">
                                    <div className="post-image">
                                    <NavLink to={`/post/${d.post_slug}`}> <img src={d.post_image} alt=""/></NavLink>
                                        <NavLink className="category" to={`/category/${d.category_name}`}>{d.category_name}</NavLink>
                                    </div>
                                    <div className="ln-content">

                                        <h2 className="title">
                                            <NavLink to={`/post/${d.post_slug}`}>
                                                {d.post_title}</NavLink>
                                        </h2>
                                        <span className='author'>By {d.display_name}</span>
                                        <span className="date">{Moment(new Date(d.date).toString()).format("LL")}</span>
                                        <p className='excerpt'>{this.truncate(`${d.post_content}`, 30)}</p>

                                        <div className="entry-details">
                                            <NavLink className='readmore' to={`/post/${d.post_slug}`}>
                                                Read More</NavLink>
                                            </div>

                                    </div>
                                </div>
                            </div>
                        })}

                    </Row>

                </React.Fragment>

                this.setState({wn});

            })
            .catch((error) => {
                console.log(error)
            })

    }

  beautyNews() {
        axios
            .get(`wp-json/home/beauty`, {})
            .then(res => {

                const data = res.data

                const beauty = <React.Fragment>

                    <Row>

                        {data.map((d) => {
                            return <div className="col-md-3">
                                <div className="ln-box1">
                                    <div className="post-image">
                                    <NavLink to={`/post/${d.post_slug}`}> <img src={d.post_image} alt=""/></NavLink>
                                        <NavLink className="category" to={`/category/${d.category_name}`}>{d.category_name}</NavLink>
                                    </div>
                                    <div className="ln-content">

                                        <h2 className="title">
                                            <NavLink to={`/post/${d.post_slug}`}>
                                                {d.post_title}</NavLink>
                                        </h2>
                                        <span className='author'>By {d.display_name}</span>
                                        <span className="date">{Moment(new Date(d.date).toString()).format("LL")}</span>
                                        <p className='excerpt'>{this.truncate(`${d.post_content}`, 30)}</p>

                                        <div className="entry-details">
                                            <NavLink className='readmore' to={`/post/${d.post_slug}`}>
                                                Read More</NavLink>
                                            </div>

                                    </div>
                                </div>
                            </div>
                        })}

                    </Row>

                </React.Fragment>

                this.setState({beauty});

            })
            .catch((error) => {
                console.log(error)
            })

    }

    fashionNews() {
        axios
            .get(`wp-json/home/fashion`, {})
            .then(res => {

                const data = res.data

                const fashion = <React.Fragment>

                    <Row>

                        {data.map((d) => {
                            return <div className="col-md-3">
                                <div className="ln-box1">
                                    <div className="post-image">
                                    <NavLink to={`/post/${d.post_slug}`}> <img src={d.post_image} alt=""/></NavLink>
                                        <NavLink className="category" to={`/category/${d.category_name}`}>{d.category_name}</NavLink>
                                    </div>
                                    <div className="ln-content">

                                        <h2 className="title">
                                            <NavLink to={`/post/${d.post_slug}`}>
                                                {d.post_title}</NavLink>
                                        </h2>
                                        <span className='author'>By {d.display_name}</span>
                                        <span className="date">{Moment(new Date(d.date).toString()).format("LL")}</span>
                                        <p className='excerpt'>{this.truncate(`${d.post_content}`, 30)}</p>

                                        <div className="entry-details">
                                            <NavLink className='readmore' to={`/post/${d.post_slug}`}>
                                                Read More</NavLink>
                                            </div>

                                    </div>
                                </div>
                            </div>
                        })}

                    </Row>

                </React.Fragment>

                this.setState({fashion});

            })
            .catch((error) => {
                console.log(error)
            })

    }

    foodNews() {
        axios
            .get(`wp-json/home/food`, {})
            .then(res => {

                const data = res.data

                const food = <React.Fragment>

                    <Row>

                        {data.map((d) => {
                            return <div className="col-md-3">
                                <div className="ln-box1">
                                    <div className="post-image">
                                    <NavLink to={`/post/${d.post_slug}`}><img src={d.post_image} alt=""/></NavLink>
                                        <NavLink className="category" to={`/category/${d.category_name}`}>{d.category_name}</NavLink>
                                    </div>
                                    <div className="ln-content">

                                        <h2 className="title">
                                            <NavLink to={`/post/${d.post_slug}`}>
                                                {d.post_title}</NavLink>
                                        </h2>
                                        <span className='author'>By {d.display_name}</span>
                                        <span className="date">{Moment(new Date(d.date).toString()).format("LL")}</span>
                                        <p className='excerpt'>{this.truncate(`${d.post_content}`, 30)}</p>

                                        <div className="entry-details">
                                            <NavLink className='readmore' to={`/post/${d.post_slug}`}>
                                                Read More</NavLink>
                                            </div>

                                    </div>
                                </div>
                            </div>
                        })}

                    </Row>

                </React.Fragment>

                this.setState({food});

            })
            .catch((error) => {
                console.log(error)
            })

    }
    moviesNews() {
        axios
            .get(`wp-json/home/movies`, {})
            .then(res => {

                const data = res.data

                const movies = <React.Fragment>

                    <Row>

                        {data.map((d) => {
                            return <div className="col-md-3">
                                <div className="ln-box1">
                                    <div className="post-image">
                                    <NavLink to={`/post/${d.post_slug}`}> <img src={d.post_image} alt=""/></NavLink>
                                        <NavLink className="category" to={`/category/${d.category_name}`}>{d.category_name}</NavLink>
                                    </div>
                                    <div className="ln-content">

                                        <h2 className="title">
                                            <NavLink to={`/post/${d.post_slug}`}>
                                                {d.post_title}</NavLink>
                                        </h2>
                                        <span className='author'>By {d.display_name}</span>
                                        <span className="date">{Moment(new Date(d.date).toString()).format("LL")}</span>
                                        <p className='excerpt'>{this.truncate(`${d.post_content}`, 30)}</p>

                                        <div className="entry-details">
                                            <NavLink className='readmore' to={`/post/${d.post_slug}`}>
                                                Read More</NavLink>
                                            </div>

                                    </div>
                                </div>
                            </div>
                        })}

                    </Row>

                </React.Fragment>

                this.setState({movies});

            })
            .catch((error) => {
                console.log(error)
            })

    }
    parentsNews() {
        axios
            .get(`wp-json/home/spooky`, {})
            .then(res => {

                const data = res.data

                const parents = <React.Fragment>

                    <Row>

                        {data.map((d) => {
                            return <div className="col-md-3">
                                <div className="ln-box1">
                                    <div className="post-image">
                                    <NavLink to={`/post/${d.post_slug}`}><img src={d.post_image} alt=""/></NavLink>
                                        <NavLink className="category" to={`/category/${d.category_name}`}>{d.category_name}</NavLink>
                                    </div>
                                    <div className="ln-content">

                                        <h2 className="title">
                                            <NavLink to={`/post/${d.post_slug}`}>
                                                {d.post_title}</NavLink>
                                        </h2>
                                        <span className='author'>By {d.display_name}</span>
                                        <span className="date">{Moment(new Date(d.date).toString()).format("LL")}</span>
                                        <p className='excerpt'>{this.truncate(`${d.post_content}`, 30)}</p>

                                        <div className="entry-details">
                                            <NavLink className='readmore' to={`/post/${d.post_slug}`}>
                                                Read More</NavLink>
                                            </div>

                                    </div>
                                </div>
                            </div>
                        })}

                    </Row>

                </React.Fragment>

                this.setState({parents});

            })
            .catch((error) => {
                console.log(error)
            })

    }
    
    talentNews() {
        axios
            .get(`wp-json/home/talent`, {})
            .then(res => {

                const data = res.data

                const talent = <React.Fragment>

                    <Row>

                        {data.map((d) => {
                            return <div className="col-md-3">
                                <div className="ln-box1">
                                    <div className="post-image">
                                    <NavLink to={`/post/${d.post_slug}`}><img src={d.post_image} alt=""/></NavLink>
                                        <NavLink className="category" to={`/category/${d.category_name}`}>{d.category_name}</NavLink>
                                    </div>
                                    <div className="ln-content">

                                        <h2 className="title">
                                            <NavLink to={`/post/${d.post_slug}`}>
                                                {d.post_title}</NavLink>
                                        </h2>
                                        <span className='author'>By {d.display_name}</span>
                                        <span className="date">{Moment(new Date(d.date).toString()).format("LL")}</span>
                                        <p className='excerpt'>{this.truncate(`${d.post_content}`, 30)}</p>

                                        <div className="entry-details">
                                            <NavLink className='readmore' to={`/post/${d.post_slug}`}>
                                                Read More</NavLink>
                                            </div>

                                    </div>
                                </div>
                            </div>
                        })}

                    </Row>

                </React.Fragment>

                this.setState({talent});

            })
            .catch((error) => {
                console.log(error)
            })

    }
    travelNews() {
        axios
            .get(`wp-json/home/travel`, {})
            .then(res => {

                const data = res.data

                const travel = <React.Fragment>

                    <Row>

                        {data.map((d) => {
                            return <div className="col-md-3">
                                <div className="ln-box1">
                                    <div className="post-image">
                                    <NavLink to={`/post/${d.post_slug}`}> <img src={d.post_image} alt=""/></NavLink>
                                        <NavLink className="category" to={`/category/${d.category_name}`}>{d.category_name}</NavLink>
                                    </div>
                                    <div className="ln-content">

                                        <h2 className="title">
                                            <NavLink to={`/post/${d.post_slug}`}>
                                                {d.post_title}</NavLink>
                                        </h2>
                                        <span className='author'>By {d.display_name}</span>
                                        <span className="date">{Moment(new Date(d.date).toString()).format("LL")}</span>
                                        <p className='excerpt'>{this.truncate(`${d.post_content}`, 30)}</p>

                                        <div className="entry-details">
                                            <NavLink className='readmore' to={`/post/${d.post_slug}`}>
                                                Read More</NavLink>
                                            </div>

                                    </div>
                                </div>
                            </div>
                        })}

                    </Row>

                </React.Fragment>

                this.setState({travel});

            })
            .catch((error) => {
                console.log(error)
            })

    }

    componentDidMount() {
        this.latestNews();
        this.getCategoryBg()
        this.worldNews()
        this.headerPosts()
        this.beautyNews()
        this.fashionNews()
        this.foodNews()
        this.moviesNews()
        this.parentsNews()
        this.talentNews()
        this.travelNews()
    }

    render() {

        if (this.state.isLoading) {
            return (
                <div className="loader">
                    <Loader type="Rings" color="#47ccde" height="100" width="100"/>
                </div>
            )
        } else {

            return (
                <div className="homepage">
                    <section className='categoryhome'>
                        <div className="grid-box">
                            {this.state.hposts}
                            
                        </div>
                    </section>
                    <section className="latest-news">
                        <div className="container">
                            <h3>Latest News</h3>
                            {this.state.ln}
                        </div>
                    </section>
                    <section className="latest-news">
                        <div className="container">
                            <h3>Entertainment</h3>
                            {this.state.wn}
                        </div>
                    </section>
                    <section className="latest-news">
                        <div className="container">
                            <h3>Beauty</h3>
                            {this.state.beauty}
                        </div>
                    </section>
                    <section className="latest-news">
                        <div className="container">
                            <h3>Fashion</h3>
                            {this.state.fashion}
                        </div>
                    </section>
                    <section className="latest-news">
                        <div className="container">
                            <h3>Food</h3>
                            {this.state.food}
                        </div>
                    </section>
                    <section className="latest-news">
                        <div className="container">
                            <h3>Movies</h3>
                            {this.state.movies}
                        </div>
                    </section>
                    <section className="latest-news">
                        <div className="container">
                            <h3>Spooky</h3>
                            {this.state.parents}
                        </div>
                    </section>
                    <section className="latest-news">
                        <div className="container">
                            <h3>Talent</h3>
                            {this.state.talent}
                        </div>
                    </section>
                    <section className="latest-news">
                        <div className="container">
                            <h3>Tour and Travel</h3>
                            {this.state.travel}
                        </div>
                    </section>
                </div>
            )
        };
    }
}

export default Homepage;
