import React, { Component } from 'react'
import './mobile.css'

class navigation extends Component {
    render() {
        return (
            <div className="navigation">
                
            </div>
        )
    }
}


export default navigation;