import React, {Component} from 'react';
import { Row, Button} from 'react-bootstrap';
import axios from '../../axios'
import './mobile.css';
import parse, {domToReact} from 'html-react-parser';
import Loader from 'react-loader-spinner'
import $ from 'jquery'
import {NavLink} from 'react-router-dom';
import {Helmet} from 'react-helmet'
import Moment from 'moment'
import {FacebookShareButton,FacebookIcon,TwitterShareButton,PinterestShareButton,PinterestIcon, EmailShareButton , EmailIcon ,TwitterIcon,WhatsappShareButton,WhatsappIcon} from 'react-share';
class Post extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {

            isLoading: true
        };
    }



    postLoad() {
        if ( this.props.match.params.slug ) {
            if ( !this.state.loadedPost || (this.state.loadedPost && this.state.loadedPost.slug !== +this.props.match.params.slug) ) {
               axios.get( 'wp-json/wp/v2/posts?slug=' + this.props.match.params.slug)
                   .then( res => {
                    
                    const data = res.data;

                    const seo = res.data

                    const desc = res.data[0].excerpt.rendered


                    

                    console.log(desc)

                    const mtag = seo.map(s => 
                        <Helmet>
                <meta charset="UTF-8" />
                <meta name="description" content={s.excerpt.rendered} />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={parse(s.title.rendered)} />
                <meta property="og:url" content='http://wespotyou.com' />
                <meta property="og:description" content={s.excerpt.rendered} />
                <meta property="og:site_name" content='WeSpotYou' />
                <meta property="og:image" content={s.better_featured_image.source_url} />
                <meta property="og:image:secure_url" content={s.better_featured_image.source_url} />
                <meta name="twitter:title" content={parse(s.title.rendered)} />
                <meta name="twitter:image" content={s.better_featured_image.source_url} />
           </Helmet>

                        
                        )

                    const post = data.map(p => 
                        <React.Fragment>
                            <div className="post-img">
                            <img src={p.better_featured_image.source_url} alt=""/>
                        </div>
                        
                        <div className="content">
                            <span style={{marginRight: '5px', fontSize: '10px'}}><i class="far fa-clock"></i></span>
                        <span style={{marginRight: '5px', fontSize: '10px'}}>{Moment(new Date(p.date).toString()).format("LL")}</span>
                        <h1>{p.title.rendered}</h1>
                        <h6><i>{p.acf.sub_heading}</i></h6>
                        <div style={{marginBottom : '10px'}}><span className='mauthimg' style={{marginRight: '5px', fontSize: '10px'}}><img src={p.writer} alt=""/></span><span className="auth" style={{marginRight: '5px', fontSize: '10px'}}>{p.author}</span></div>
                        <div className="time-auth">
                            
                        </div>
                            <div className="mpcnt">
                            {parse(p.content.rendered)}
                            </div>
                        </div>
                        </React.Fragment>
                        )
                    
    
                       this.setState({isLoading:false, post,  mtag})
                      
                   } );
                   
           }
       }

    }

    sideBar(){
        axios
        .get(`wp-json/acf/v3/options/options`, {})
        .then(res => {

            const sbdata = res.data.acf

            const sideBar = sbdata.map(s => 
                <div className="col-md-12">
                               
                                </div>
                )

           

            this.setState({})

        })
        .catch((error) => {
            console.log(error)
        })
    }

   

    componentDidMount() {
        this.postLoad();
        this.sideBar()
       
    }

    render() {

        if (this.state.isLoading) {
            return (
                <div className="loader">
                    <Loader type="Rings" color="#47ccde" height="100" width="100"/>
                </div>
            )
        } else {

            return (
                <div className="mpost">
                    {this.state.mtag}
                    <div className="container">
                        <div className="row">
                            
                               
                                <div className="cont">
                                {this.state.post}
                                     <div className="social">
                        <FacebookShareButton url = {window.location.href} quote = {'Hello this is manish'} > <FacebookIcon size={25}></FacebookIcon> </FacebookShareButton>
                        <WhatsappShareButton url = {window.location.href} quote = {'Hello this is manish'} > <WhatsappIcon size={25}></WhatsappIcon> </WhatsappShareButton>
                        <TwitterShareButton url = {window.location.href} quote = {'Hello this is manish'} > <TwitterIcon size={25}></TwitterIcon> </TwitterShareButton>
                        <EmailShareButton url = {window.location.href} subject='Read This Awesome Post by We Spot You' openWindow={true}> <EmailIcon size={25}></EmailIcon> </EmailShareButton>
                        {/* <PinterestShareButton media = {window.location.href} > <PinterestIcon size={50}></PinterestIcon> </PinterestShareButton> */}
                        </div>
                                </div>
                                    
                               
                        </div>
                       
                    </div>
                   
                </div>
            )
        };
    }
}

export default Post;
