import React, { Component } from 'react'
import './videos.css'
import YouTube from 'react-youtube';
import axios from 'axios';
import Moment from 'moment'
import Loader from 'react-loader-spinner'
import parse, {domToReact} from 'html-react-parser';
import ytb from './../../assets/videos/ytb.mp4'
const opts = {
    height: 'auto',
    width: '100%',
    playerVars: { 
      autoplay: 0
    }
  };

 class Videos extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: true,
        }
      
    }

    receivedata() {
        axios
            .get(`wp-json/wp/v2/videos`, {})
            .then(res => {
              
                const data = res.data;

                const videos = data.map(v => 
                                    <React.Fragment>
                                        <div className="col-md-3 video">
                                       <div className="col-md-12">
                                       <YouTube
                        videoId={v.acf.id}
                        opts={opts}
                       
                    />
                    <p className='vtime'><span><i class="far fa-clock"></i></span>{Moment(new Date(v.date).toString())
                                    .format("LL")}</p>
                    <h4>{parse(v.title.rendered)}</h4>
                                       </div>
                                        </div>
                                    </React.Fragment>
                    )

               

                this.setState({videos, isLoading: false});
            })
    }

    componentDidMount() {
        this.receivedata()
        
        
    }

    render() {

        if (this.state.isLoading) {
            return (
                <div className="loader">
                    <Loader type="Rings" color="#47ccde" height="100" width="100"/>
                </div>
            )
        } else {
        
        return (
            <div className='videos'>
                <div className="headerbg">
                   <h2>{this.props.match.params.slug}</h2>
                   <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
    <source src={ytb} type="video/mp4" />
  </video>            
                        
                    </div>
                <div className="container">
                    <div className="row">
                    {this.state.videos}
                    </div>
                </div>
            </div>
        )
        }
    }
}

export default Videos