import React, {Component} from 'react';
import { Row, Button} from 'react-bootstrap';
import axios from '../../axios'
import './categories.css';
import Moment from 'moment'
import parse, {domToReact} from 'html-react-parser';
import Loader from 'react-loader-spinner'
import $ from 'jquery'
import {NavLink} from 'react-router-dom';
import {Helmet} from 'react-helmet'
import ReactPaginate from 'react-paginate';
import fashion from './../../assets/videos/fashion.mp4'
import {isMobileOnly} from 'react-device-detect';
class Fashion extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            cat:[],
            isLoading: true,
            offset: 0,
            perPage:11,
            currentPage: 0,
            query: '',
            results: [],
        };
    }
    truncate(str, no_words) {
        var tro = str
            .split(" ")
            .splice(0, no_words)
            .join(" ");
        var regex = /(<([^>]+)>)/ig

        return tro.replace(regex, "");
    }
    

    handleInputChange = () => {
        this.setState({
          query: this.search.value
        }, () => {
          if (this.state.query && this.state.query.length > 1) {
            if (this.state.query.length % 2 === 0) {
              this.catPostLoad()
            }
            
          } 
          else if (this.state.query.length === 0) {
            this.catPostLoad()
          }
        })
      }


    catPostLoad() {
 axios.get( `/wp-json/wp/v2/posts?categories=8`)
                   .then( res => {

                    const data = res.data;
                    const count = data.length
                    console.log(data)
                    const split = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                    var child_el = '';

                    

                    const cat = <React.Fragment>

                    <Row>

                        {split.map((d, i) => {
                            if (i === 0) {
                                return <div className="col-md-12">
                                    <div className="ln-box1">
                                        <div className="post-image1">
                                        <NavLink to={`/post/${d.slug}`}><img src={d.better_featured_image.source_url} alt=""/></NavLink>
                                            <NavLink className="category" to={`/category/fashion`}>Fashion</NavLink>
                                        </div>

                                        <div className="ln-content">

                                            <h2 className="title">
                                                <NavLink to={`/post/${d.slug}`}>
                                                    {d.title.rendered}</NavLink>
                                            </h2>
                                            <span className='author'>By {d.author}</span>
                                            <span className="date">{Moment(new Date(d.date).toString()).format("LL")}</span>
                                            <p className='excerpt'>{this.truncate(`${d.content.rendered}`, 50)}</p>
                                            <div className="entry-details">
                                                <NavLink className='readmore' to={`/post/${d.slug}`}>
                                                    Read More</NavLink>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            } else {
                                child_el += `<div class=${isMobileOnly ? '' : 'col-md-6'}> <div class="ln-box2">
                    <div class="post-image">
                    <NavLink id='remove' to='/post/${d
                        .slug}'><img src=${d.better_featured_image.source_url} alt=""/></NavLink>
                    <NavLink class="category" id='remove' to='/category/fashion'>Fashion</NavLink>
                    </div>
                     <div class="ln-content">
                     
                     <h2 class='title'><NavLink id='remove' to='/post/${d
                                    .slug}'>${d
                                    .title.rendered}</NavLink></h2>
                     <span class='author'>By ${d
                                    .author}</span>
                     <span class="date" id='date'>${Moment(new Date(d.date).toString())
                                    .format("LL")}</span>
                     <p class='excerpt'>${this
                                    .truncate(`${d.content.rendered}`, 20)}</p>
                                        <div className="entry-details">
                                        <NavLink id='remove' className='readmore' to='/post/${d
                                    .slug}'>
                                            Read More</NavLink>
                                        </div>
                     </div>
                     </div></div>`
                            }
                        })}

                        <div className="col-md-12">

                            <div>{parse(child_el, {
                                    replace: ({attribs, children}) => attribs && attribs.id === 'remove' && <NavLink to={attribs.to}>{domToReact(children)}</NavLink>
                                })}</div>

                        </div>

                    </Row>

                </React.Fragment>
                    
    
                       this.setState({isLoading:false, cat,pageCount: Math.ceil(data.length / this.state.perPage),count,})
                      
                   } );
                   
    }

    sideBar(){
        axios
        .get(`wp-json/acf/v3/sidebar`, {})
        .then(res => {

            const sbdata = res.data[0].acf.highlighted_post

            console.log(sbdata)

            const sideBar = sbdata.map(s => 
                <div className="col-md-12 sbpost">
                               <div className="sbimg">
                                   <NavLink to={`/post/${s.slug}`}><img src={s.featured_image} alt=""/></NavLink>
                               </div>
                               <div className="sbtitle">
                               <NavLink to={`/post/${s.slug}`}> {s.post_title}</NavLink>
                               </div>
                                </div>
                )


            this.setState({sideBar})

        })
        .catch((error) => {
            console.log(error)
        })
    }

    
     componentDidMount() {
        this.catPostLoad();
        this.sideBar()
    }

    render() {
        let paginate;
    if( this.state.count < 11){
      paginate = <div></div>
    }
    else {
       paginate = 
       <ReactPaginate previousLabel={"previous"}
                       nextLabel={"next"}
                       breakLabel={"..."}
                       breakClassName={"break-me"}
                       pageCount={this.state.pageCount}
                       marginPagesDisplayed={2}
                       pageRangeDisplayed={5}
                       onPageChange={this.handlePageClick}
                       containerClassName={"pagination"}
                       subContainerClassName={"pages pagination"}
                       activeClassName={"active"} 
                       
                       /> 


    }

        if (this.state.isLoading) {
            return (
                <div className="loader">
                    <Loader type="Rings" color="#47ccde" height="100" width="100"/>
                </div>
            )
        } else {

            return (
                <div className="categories">
                   <div className="headerbg">
                   <h2>{this.props.match.params.slug}</h2>
                   <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
    <source src={fashion} type="video/mp4" />
  </video>            
                        
                    </div>
                   
                   
                    <div className="container">
                        <div className="row">
                            <div className="blogleft">
                                {this.state.cat}
                            </div>
                            <div className="blogright">
                            <h3>Also Read</h3>
                            <div>
                                    {this.state.sideBar}
                            </div>
                            </div>
                        </div>
                       {paginate}
                    </div>
                </div>
            )
        };
    }
}

export default Fashion;
