import React, {Component} from 'react';
import {Row} from 'react-bootstrap';
import axios from '../../axios'
import './work.css';

import Loader from 'react-loader-spinner'

class Work extends Component {
    constructor(props) {
        super(props);

        this.state = {

            isLoading: true
        };
    }
    recievedata() {
        axios
            .get(`wp-json/acf/v3/pages/12`, {})
            .then(res => {
                const work = res.data.acf;

                const section1 = <React.Fragment>
                    <Row>
                        <div className="heading">
                            <h3>{work.section_1.text}</h3>
                            <div className="sideline"></div>
                        </div>
                    </Row>
                    <Row>
                        <div className="centr">
                            <div className="col-md-4 sideimg">
                                <img src={work.section_1.image.url} alt=""/>
                            </div>
                            <div className="col-md-8">
                                <p>{work.section_1.description}</p>
                            </div>
                        </div>
                    </Row>
                </React.Fragment>

                const section2 = <React.Fragment>
                    <Row>
                        <div className="heading">
                            <h3>{work.section_2.text}</h3>
                            <div className="sideline"></div>
                        </div>
                    </Row>
                    <Row>
                        <div className="centr">
                            <div className="col-md-8">
                                <p>{work.section_2.description}</p>
                            </div>
                            <div className="col-md-4 sideimg">
                                <img src={work.section_2.image.url} alt=""/>
                            </div>
                        </div>
                    </Row>
                </React.Fragment>

                const banner = <React.Fragment>
                    <Row>
                        <h2>{work.banner.text}:
                            <a href={`mailto:${work.banner.mail}`}>{work.banner.mail}</a>
                        </h2>
                    </Row>
                </React.Fragment>

                const section3 = <React.Fragment>
                    <Row>
                        <div className="heading">
                            <h3>{work.section_3.text}</h3>
                            <div className="sideline"></div>
                        </div>
                    </Row>
                    <Row>
                        <div className="centr">
                            <div className="col-md-4 sideimg">
                                <img src={work.section_3.image} alt=""/>
                            </div>
                            <div className="col-md-8">
                                <p>{work.section_3.description}</p>
                            </div>
                        </div>
                    </Row>
                </React.Fragment>

                const section4 = <React.Fragment>
                    <Row>
                        <div className="heading">
                            <h3>{work.section_4.text}</h3>
                            <div className="sideline2"></div>
                        </div>
                    </Row>
                    <Row>
                        <div className="centr">
                            <div className="col-md-8">
                                <p>{work.section_4.description}</p>
                            </div>
                            <div className="col-md-4 sideimg">
                                <img src={work.section_4.image.url} alt=""/>
                            </div>
                        </div>
                    </Row>
                </React.Fragment>

                this.setState({
                    section1,
                    section2,
                    section3,
                    section4,
                    banner,
                    isLoading: false
                });

            })
            .catch((error) => {
                console.log(error)
            })

    }

    componentDidMount() {
        this.recievedata();
        document.title = "Our Work"
    }

    render() {

        if (this.state.isLoading) {
            return (
                <div className="loader">
                    <Loader type="Rings" color="#5dc9d6" height="100" width="100"/>
                </div>
            )
        } else {

            return (
                <div className="work">
                    <section className="pageheader">
                        <h2>Our Work</h2>
                    </section>
                    <section>
                        <div className="container">
                            {this.state.section1}
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            {this.state.section2}
                        </div>
                    </section>
                    <div className="workmbanner">
                        {this.state.banner}
                    </div>
                    <section>
                        <div className="container">
                            {this.state.section3}
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            {this.state.section4}
                        </div>
                    </section>
                </div>
            )
        };
    }
}

export default Work;
