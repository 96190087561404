import React, { Component } from 'react';
import './footer.css'
import axios from '../../axios'
import {NavLink} from 'react-router-dom';

class Footer extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
        
      
    };
}
recievedata() {
  axios
      .get(`wp-json/menu/footer`, {})
      .then(res => {
          const footer = res.data

         const menu = footer.map(f => 
          <ul>
            <li>
              <NavLink to={f.url}>{f.post_title}</NavLink>
            </li>

          </ul>
          )
          



          this.setState({
             
            menu
          });

      })
      .catch((error) => {
          console.log(error)
      })

}

componentDidMount() {
 this.recievedata()
}
  render() {
    return (
        <div className="footer">
          {
            this.state.menu
          }
        </div>
    );
  }
}

export default Footer;
