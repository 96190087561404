import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import { hydrate, render } from "react-dom";


axios.defaults.baseURL = 'https://backend.wespotyou.com/';
axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios
    .interceptors
    .request
    .use(request => {

        // Edit request config
        return request;
    }, error => {

        return Promise.reject(error);
    });

axios
    .interceptors
    .response
    .use(response => {

        return response;
    }, error => {

        return Promise.reject(error);
    });

    const rootElement = document.getElementById("root");
    if (rootElement.hasChildNodes()) {
      hydrate(<App />, rootElement);
    } else {
      render(<App />, rootElement);
    }


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls. Learn
// more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
