import React, {Component} from 'react';

import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import {Navbar, Nav, NavItem, NavDropdown, MenuItem} from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import $ from 'jquery'
import {NavLink} from 'react-router-dom';
import Sidebar from "react-sidebar";



const mql = window.matchMedia(`(max-width: 500px)`);

class Header extends Component {

    constructor() {
        super();
        this.state = {
            sidebarDocked: mql.matches,
            sidebarOpen: false
        }
        this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    }

    getmenu() {
      axios
          .get(`wp-json/menu/mobile`, {})
          .then(res => {
              const menus = res.data;

              

              let result = menus.map(
            
             

          function(a){
              return  <React.Fragment key={a.ID}>
                  <LinkContainer to={`${a.url}`}>
                 
             
              {a.submenu.some(item => item.ID > 0) ? <NavDropdown eventKey={3} title={a.title} id="basic-nav-dropdown">
      
      {a.submenu.map(tit =>
          <LinkContainer to={`${tit.url}`}>
          <NavItem eventKey={3.1}>{tit.title}</NavItem>
          </LinkContainer>
          )}
      
    </NavDropdown>
    :
    <NavItem className={`item${a.ID}`}>{a.title}</NavItem>
  }
              
  
  
              
             
            </LinkContainer>
             
              </React.Fragment>
          }
             
              
              );

              this.setState({result});
          })
  }

    
    receivedate() {
        axios
            .get(`wp-json/acf/v3/options/options`, {})
            .then(res => {
               const data = res.data.acf;
               const logo = data.logo
               

                this.setState({logo});
            })
    }
    componentDidMount() {
        this.receivedate()
        mql.addListener(this.mediaQueryChanged);
        this.getmenu()
        
        
    }
 
      onSetSidebarOpen(open) {
        this.setState({ sidebarOpen: open });
      }
     
      mediaQueryChanged() {
        this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
      }

    render() {
const side = <React.Fragment>
  <div className="mlogo">
<NavLink to='/'><img src={this.state.logo} alt=""/></NavLink>
</div>
<div className="mmenu">
  {this.state.result}
</div>
</React.Fragment>
        return (

            <div className="mheader">



<Sidebar
        sidebar={side}
        open={this.state.sidebarOpen}
        transitions={true}
        sidebarClassName={'sidebar'}
        onSetOpen={this.onSetSidebarOpen}
      >
        
       
        <div class="hamburger" onClick={() => this.onSetSidebarOpen(true)}>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
</div>
</Sidebar>


            </div>
        );
    }
}

export default Header;
