import React, { Component } from 'react'
import './contact.css'
import { Row, Button} from 'react-bootstrap';
import axios from '../../axios'
import parse, {domToReact} from 'html-react-parser';
import Loader from 'react-loader-spinner'
import { ValidatorForm } from 'react-form-validator-core';
import TextValidator from './textvalidator'

 class contact extends Component {
    constructor(){
        super();
        this.state={
        fname: '',
        lname:'',
        femail: '',
        phone: '',
        message: ''
        }
        }
        onChange = (e) => {
            this.setState({ [e.target.name]: e.target.value });
          }
          onSubmit = (e) => {
            e.preventDefault();
            var form = document.querySelector('form');
            var data = new FormData(form);
    
            axios({
              method: 'post',
              url: 'wp-json/contact-form-7/v1/contact-forms/298/feedback',
              data:data,
              config: { headers: {'Content-Type': 'multipart/form-data' }}
              })
              .then(function (response) {
                  //handle success
                  console.log(response);
              })
              .catch(function (response) {
                  //handle error
                  console.log(response);
              });
          }
  
   
    render() {
        const { fname, lname, femail, phone, message } = this.state;
        return (
            <div>
               
               <div className="container">
                   <Row>
                  <div className="contactcont">
                    
                      <br/>
                      
                 {/* <form onSubmit={this.onSubmit}>
            <input
              type="text"
              name="fname"
              value={fname}
              onChange={this.onChange}
            />
            <input
              type="text"
              name="femail"
              value={femail}
              onChange={this.onChange}
            />
            <button type="submit">Submit</button>
          </form> */}

          <div className="cntform">
          <h2 style={{textAlign : 'center'}}>Contact Us</h2>
          <br/>
          <ValidatorForm
            ref="form"
            onSubmit={this.onSubmit}
        >

<Row>
<div className="col-md-6">
<div className="fname oneline">
<p>First Name : </p><TextValidator
               
                type="text"
                name="fname"
                onChange={this.onChange}
                value={fname}
                validators={['required']}
                errorMessages={['this field is required', 'First Name is not valid']}
            />
</div>
</div>
           <div className="col-md-6">
           <div className="fname oneline">
           <p>Last Name : </p><TextValidator
               
                type="text"
                name="lname"
                onChange={this.onChange}
                value={lname}
                validators={['required']}
                errorMessages={['this field is required', 'Last Name is not valid']}
            />
            </div>
           </div>
</Row>

<Row>
 <div className="col-md-6">
 <div className="oneline">
  <p>Email : </p>
            <TextValidator
               
                type="email"
                name="femail"
                value={femail}
                onChange={this.onChange}
                validators={['required', 'isEmail']}
                errorMessages={['this field is required', 'Email is not valid']}
            />
  </div>
 </div>
 <div className="col-md-6">
 <div className="oneline">
  <p>Phone : </p>
            <TextValidator
               
                type="tel"
                name="phone"
                value={phone}
                onChange={this.onChange}
                validators={['required', 'isNumber']}
                errorMessages={['this field is required', 'Phone Number is not valid']}
            />
  </div>
 </div>
            </Row>
            <Row>
              <div className="col-md-12">
              <div className="oneline message">
  <p>Message : </p>
            {/* <TextValidator
                onChange={this.handleChange}
                type="text"
                name="message"
                value={message}
                onChange={this.onChange}
                validators={['required']}
                errorMessages={['this field is required', 'Message is not valid']}
            /> */}
            <textarea 
             name="message"
            value={message}
             onChange={this.onChange}
            ></textarea>
  </div>
              </div>
            </Row>
             <button type="submit">submit</button>
        </ValidatorForm>
        
        </div>

                  </div>
                   </Row>
               </div>
            </div>
        )
        }
}

export default contact