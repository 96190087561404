import React, {Component} from 'react';
import './header.css';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import {Navbar, Nav, NavItem, NavDropdown, MenuItem} from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import $ from 'jquery'
import {NavLink} from 'react-router-dom';

class Header extends Component {

    constructor() {
        super();
        this.state = {
            menus: []
        }
    }
    jQueryCode = () => {

        $('.navbar-nav').on('click', 'li', function() {
            $('.navbar-nav li.active').removeClass('active');
            $(this).addClass('active');
        
        });
    
   
 }
 getlogo() {
    axios
        .get(`wp-json/acf/v3/options/options`, {})
        .then(res => {
           const data = res.data.acf;
           const logo = data.logo
           

            this.setState({logo});
        })
}
    receivedate() {
        axios
            .get(`wp-json/menu/primary`, {})
            .then(res => {
                const menus = res.data;

                

                let result = menus.map(
                
               

            function(a){
                return  <React.Fragment key={a.ID}>
                    <LinkContainer to={`${a.url}`}>
                   
               
                {a.submenu.some(item => item.ID > 0) ? <NavDropdown eventKey={3} title={a.title} id="basic-nav-dropdown">
        
        {a.submenu.map(tit =>
            <LinkContainer to={`${tit.url}`}>
            <NavItem eventKey={3.1}>{tit.title}</NavItem>
            </LinkContainer>
            )}
        
      </NavDropdown>
      :
      <NavItem className={`item-${a.menu_item_parent > 0 ? 'yes' : 'no'}`}>{a.title}</NavItem>
    }
                
    
    
              </LinkContainer>
               
                </React.Fragment>
            }
               
                
                );

                this.setState({result});
            })
    }
    componentDidMount() {
        this.receivedate()
        this.jQueryCode()
        this.getlogo()
        
    }

    render() {

        return (

            <div className="header">

                <Navbar collapseOnSelect>
                    <div className="container">
                        <Navbar.Header>

                            <Navbar.Brand>
                                <NavLink to="/"><div className="dlogo">
                                <img src={this.state.logo} alt=""/></div></NavLink>
                            </Navbar.Brand>
                            <Navbar.Toggle/>
                        </Navbar.Header>
                        <Navbar.Collapse>
                            <Nav pullRight={true}>
                                {this.state.result }
                               
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                </Navbar>

            </div>
        );
    }
}

export default Header;
